.hero {
    &__text-box {
        margin-left: 4.2rem;
        &>h1 {
            margin-top: 0;
            margin-bottom: 0;
            margin-left: 1.4rem;
            color: #ffffff;
            font-size: calc(75rem / 14); } }
    &__position-box {
        margin-top: 1rem; }
    &__text-position {
        margin-left: 1.4rem;
        color: #515152;
        font-size: 1.2rem; } }


.home {
    &__logo-in-title {
        height: 4rem;
        margin-left: 1rem; }

    &__btn-contact {
        position: relative;
        overflow: hidden;

        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        width: 220px;
        margin-top: 1.4rem;
        margin-left: 1.4rem;

        border: 1px solid #08fdd8;
        color: #08fdd8;
        font-size: 1.2rem;

        cursor: pointer;
        transition: color 0.5s;
        &:hover {
            color: #1d1d1d;
            &>.home__btn-contact__base {
                left: -1rem; } }
        &__base {
            z-index: -1;
            position: absolute;
            background: #08fdd8;
            height: 30rem;
            width: 30rem;
            top: -15rem;
            left: -35rem;
            transform: rotate(25deg);
            transition: all 0.5s; } } }

@media screen and ( max-width: 1200px ) {
    .hero__text-box {
        margin-left: 0;
        &>h1 {
            font-size: calc(50rem / 14); } } }
