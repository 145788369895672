.skills {
    &__title {
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 2.8rem;
        color: #08fdd8;
        font-size: calc(50rem / 14);
        &>span {
            color: #08fdd8; } }
    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-right: 2rem; }
    &__tag {
        padding: 5px;

        border-radius: 5px;
        color: #08fdd8;
        border: 1px solid #fff; }
    &__stat {
        margin-top: 1rem;
        display: flex;
        flex-wrap: wrap;
        gap: 7px;
        &-block {
            width: 100%;
            padding: 1rem;
            margin: 1rem;
            background: #2b2b2b;
            border-radius: 10px;
            &__title {
                color: #08fdd8;
                font-size: 1.2rem; } }
        &-name {
            margin-top: 1rem;
            color: #fff; }
        &-line {
            width: 100%;
            margin-top: 0.5rem;
            height: 2px;
            background: #1D1D1D;
            border-radius: 2px;
            overflow: hidden;
            &__indicator {
                height: 100%;
                &__fill {
                    width: 0%;
                    height: 100%;
                    background: #08fdd8;
                    border-radius: 2px;
                    animation: stat 1s linear 0.3s 1 normal forwards; } } } } }


@keyframes stat {
    0% {
        width: 0%; }
    100% {
        width: 100%; } }


@media screen and ( max-width: 580px ) {
    .skills {
        &__container {
            padding-right: 0rem; } } }


