*, *:before, *:after {
  box-sizing: border-box;
  outline: none;
  text-decoration: none;
  user-select: none; }

html {
  font-size: 14px; }

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  overflow-x: hidden;
  background: #1D1D1D; }

img {
  max-width: 100%; }

// /*Убрать полосу прокрутки для всей страницы*/
::-webkit-scrollbar {
  width: 0; }

.container {
  position: relative;
  max-width: 1200px;
  padding: 0 3rem;
  margin: 0;
  margin-left: calc(130rem / 14);
  &--column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh; }
  &--two-blocks {
    display: flex;
    min-width: calc(100vw - (88rem / 14));
    justify-content: space-between; } }

.container-block {
  flex-basis: 45%;
  &--column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh; } }

.section {
  &--full-screen {
    width: 100vw;
    min-height: 100vh; } }

.section-title {
  margin: 0;
  font-size: 4rem; }

.link {
  color: #08fdd8; }

.tag {
  font-family: 'La Belle Aurore', cursive;
  color: #515152;
  font-size: 1.3rem;
  padding: 5px 0;
  &--0 {
    margin-left: 0; }
  &--1 {
    margin-left: 1.4rem; }
  &--2 {
    margin-left: 2.8rem; } }

.fade-in-up {
  position: relative;
  animation-name: fadeUp;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0; }

.fade-in-down {
  position: relative;
  animation-name: fadeDown;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0; }

.popup {
  min-width: 100px;
  text-align: center;
  position: fixed;
  bottom: 10px;
  right: 10px;
  background: #2b2b2b;
  padding: 20px 20px;
  color: #08fdd8; }

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(30px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes fadeDown {
  0% {
    opacity: 0;
    transform: translateY(-30px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

.fade-in-right {
  position: relative;
  animation-name: fadeRight;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0; }

@keyframes fadeRight {
  0% {
    opacity: 0;
    transform: translateX(30px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

.mobile {
  &-v {
    display: none; } }

@media screen and ( max-width: 1200px ) {
  html {
    font-size: 12px; }
  .mobile {
    &-v {
      display: block; }
    &-h {
      display: none; } }

  .tag {
    font-size: 1.3rem;
    &--1 {
      margin-left: 0; }
    &--2 {
      margin-left: 0;
      display: none; } }
  .container {
    &--two-blocks {
      flex-direction: column;
      justify-content: start;
      align-items: center; } }

  .container-block {
    flex-basis: 100%;
    width: 100%;
    min-height: 30vh;
    &--column {
      min-height: auto; } } }
@media screen and ( max-width: 580px ) {
  .container {
    margin: 0; } }


