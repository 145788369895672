.contact {
    &__title {
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 2.8rem;
        color: #08fdd8;
        font-size: calc(50rem / 14);
        &>span {
            color: #08fdd8; } }
    &__text-prev-form {
        color: #fff;
        margin: 10px;
        margin-left: 2.8rem;
        font-size: calc(16rem / 14); }
    &__form {
        margin: 10px 0;

        padding: 0 2rem;
        max-width: 100%;
        position: relative;
        display: flex;
        flex-direction: column; }
    &__input {
        position: relative;
        margin: 10px 0;
        &>input, textarea {
            display: block;
            width: 100%;
            border: 0;
            background: #2b2b2b;
            height: 50px;
            font-size: 16px;
            color: #8d8d8d;
            padding: 0 20px; }
        &>input:focus + label {
            width: 100%; }
        &>textarea:focus + label {
            width: 100%; }

        &>label {
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            height: 2px;
            width: 0%;
            background: #08fdd8;
            transition: 0.2s; }

        &-group {
            display: flex;
            justify-content: space-between;
            align-items: center;
            &>.contact__input {
                width: 49%;
                margin: 0; } } }
    &__message {
        display: block;
        margin: 0;
        height: 150px;
        &>textarea {
            padding: 20px;
            width: 100%;
            height: 100%;
            resize: none; } }
    &__btn-contact {
        position: relative;
        overflow: hidden;

        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        width: 220px;
        margin-top: 1.4rem;
        margin-left: auto;

        border: 1px solid #08fdd8;
        color: #08fdd8;
        font-size: 1.2rem;

        cursor: pointer;
        transition: color 0.5s;
        &:hover {
            color: #1d1d1d;
            &>.contact__btn-contact__base {
                left: -1rem; } }
        &__base {
            z-index: -1;
            position: absolute;
            background: #08fdd8;
            height: 30rem;
            width: 30rem;
            top: -15rem;
            left: -35rem;
            transform: rotate(25deg);
            transition: all 0.5s; } }
    &__map {
        flex-basis: 40% !important;
        filter:  url('#dark-blue-sepia') invert(1) hue-rotate(262deg); } }

@media screen and ( max-width: 1200px ) {
    .contact__content {
        min-height: 100vh !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: space-between !important;
        padding-right: 9rem; }
    .contact {
        &__title {
            margin-left: 10px;
            margin-bottom: 2rem;
            text-align: center; }
        &__text-prev-form {
            margin-left: 10px;
            margin-bottom: 2rem;
            text-align: center; } }
    .contact__map {
        display: none; } }

@media screen and ( max-width: 580px ) {
    .contact {
        padding: 0 1rem !important;
        &__content {
            padding-right: 0; }
        &__form {
            padding: 0; }
        &__input-group {
            display: flex;
            flex-direction: column; }
        &__name {
            width: 100% !important;
            margin-bottom: 10px !important; }
        &__email {
            width: 100% !important; }
        &__btn-contact {
            margin: 2rem auto; } } }
