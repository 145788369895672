.title-letter {
    display: inline-block;
    color: #ffffff;
    transition: color 0.3s;
    letter-spacing: -2.5px;

    &:hover {
        color: #08fdd8; }
    &--hidden {
        opacity: 0; } }
