#myCanvasContainer {
    width: 80%;
    height: 100%;
 }    // padding-right: 3rem

@media screen and ( max-width: 1200px ) {
    #myCanvasContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        width: 100%;
        height: 30vh; } }
