.work {
    &__container {
        max-width: 100vw;
        padding: 3rem;
        margin: 0 0 0 calc(130rem / 14); }
    &__title {
        font-size: 25px;
        color: #08fdd8; } }

.projects {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }

.project {
    position: relative;
    flex-basis: 49%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    padding: 25px;
    background: #2b2b2b;
    color: #fff;
    transition: 0.3s;
    border-radius: 7px;
    &:hover {
        box-shadow: 0px 5px 5px #2b2b2b7e;
        & .project__title {
            color: #08fdd8; } }
    &__img {
        width: 100%;
        display: flex;
        align-items: center;
        border-radius: 7px; }
    &__content {
        margin-bottom: 1rem;
        width: 100%; }
    &__title {
        font-size: 18px;
        margin-bottom: 1rem;
        transition: 0.2s; }
    &__description {
        line-height: 1.4;
        color: #8d8d8d; }
    &__status {
        position: absolute;
        padding: 3px;
        top: 10px;
        right: 10px;
        color: #8d8d8d;
        border: 1px solid #8d8d8d;
        border-radius: 10px; } }


@media screen and ( max-width: 900px ) {
    .project {
        flex-basis: 100%; } }

@media screen and ( max-width: 580px ) {
  .work__container {
    margin: 0; } }
