.side-menu {
    position: fixed;
    z-index: 90;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    width: calc(130rem / 14);
    height: 100vh;

    background: #181818; }

.logo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    background: #000000;

    width: 100%;
    padding: calc(20rem / 14) 0;
    &__img {
        height: calc(60rem / 14);
        width: calc(60rem / 14); }
    &__name {
        letter-spacing: -1.5px;
        font-size: 1.7rem;
        font-weight: 700;
        color: #ffffff;
        margin-top: 0.3rem; }
    &__position {
        font-size: 0.85rem;
        font-weight: 300;
        color: #a5a5a5; } }

.nav {
    border-top: 1px solid #282828;
    width: 100%;
    padding-bottom: 3rem;
    &__item {
        padding: 0.8rem 0;
        text-align: center;
        font-weight: 400;
        font-size: 1.2rem;
        border-bottom: 1px solid #282828;
        color: #909096;
        transition: all 0.3s;
        &:hover {
            color: #08fdd8; } } }

.social-networks {
    padding-bottom: calc(20rem / 14);
    &>a>svg {
        &>path {
            transition: fill .3s;
            fill: #909096; }
        &:hover>path {
            fill: #08fdd8; } } }

.hamburger-menu {
    &__button {
        display: none;
        position: fixed;
        right: 10px;
        top: 10px;
        z-index: 100;

        padding: 1rem;

        background-color: rgba(0,0,0,.3);
        border-radius: 3px;
        cursor: pointer; }
    &__screen {
        position: fixed;
        right: 0;
        top: 0;
        z-index: 90;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        height: 100vh;
        width: 100vw;

        background: #181818;
        transition: opacity 0.3s; } }

@media screen and ( max-width: 580px ) {
    .hamburger-menu__button {
        display: block; }
    .side-menu {
        display: none; } }
